body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none !important;
  color: inherit !important;
}
.MobileFilterClass{
    background-color:#e9ecef;
    position: absolute;
    width: -webkit-max-content;
    width: max-content;
    z-index: 1;
    border-radius: 5px;
    padding-inline: 5px;
}

.cartPage{
    margin-top: 100px;
}

@media (max-width: 768px) {
    .responsive-text {
        font-size: 25px;
      }
}

@media (min-width: 768px) {
    .responsive-text {
        font-size: 40px;
      }
}

.LoadingDiv {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    text-align: center;
  }
